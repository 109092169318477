<template>
  <v-container fluid>
    <!-- logo & social section -->
    <v-row style="background-color: #e4e4e4" class="pa-4">
      <v-col
        cols="12"
        md="6"
        class="align-self-center d-flex justify-center justify-md-start"
      >
        <v-img
          max-width="200"
          style="filter: grayscale(100%)"
          src="../../assets/BlueOrangeLogo.png"
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="align-self-center d-flex justify-center justify-md-end"
      >
        <v-btn
          class="mr-3 py-5"
          color="white"
          v-for="socialLogo in socialLogos"
          :key="socialLogo.link"
          :href="socialLogo.link"
          target="_blank"
        >
          <font-awesome-icon
            :icon="socialLogo.icon"
            style="color: grey; font-size: 1.1rem"
          ></font-awesome-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- sitemap & blind review section -->
    <v-row style="background-color: #f6f6f6" class="px-4 py-2">
      <v-col>
        <v-row class="d-flex justify-start align-self-center">
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="6">
                <div>
                  <h3 class="d-flex justify-start">Συνεργάτες</h3>
                  <div
                    style="width: 200px; border-bottom: 1px solid black"
                  ></div>
                  <div class="d-flex justify-start py-2 font-weight-bold">
                    Έχεις E-shop;
                  </div>
                  <div class="d-flex justify-start text-left">
                    Δες πώς μπορείς να αξιοποιήσεις τις νέας γενιάς υπηρεσίες
                    courier που προσφέρουμε.
                  </div>
                  <div class="d-flex justify-start py-2 font-weight-bold">
                    Είσαι οδηγός;
                  </div>
                  <div class="d-flex justify-start text-left">
                    Γίνε διανομέας, με ευέλικτο ωράριο και ανταγωνιστικές
                    απολαβές.
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div>
                  <h3 class="d-flex justify-start">Εταιρεία</h3>
                  <div
                    style="width: 200px; border-bottom: 1px solid black"
                  ></div>
                  <a
                    class="d-flex justify-start py-2 clickableButton"
                    href="https://blog.stoferno.gr/"
                    target="_blank"
                  >
                    Stoferno Blog
                  </a>
                  <div class="d-flex justify-start text-left">
                    Μάθε νέα & best practices που αφορούν Courier & E-Commerce
                  </div>
                  <div
                    class="d-flex justify-start py-2 clickableButton"
                    style="cursor: pointer"
                    @click="openCareer"
                  >
                    Ψάχνεις για Καριέρα;
                  </div>
                  <div class="d-flex justify-start text-left">
                    <span
                      >Γίνε μέλος της ομάδας μας. Δες τις ανοιχτές θέσεις
                      εργασίας.</span
                    >
                  </div>
                  <div class="d-flex justify-start py-2 font-weight-bold">
                    FAQs - Συχνές Ερωτήσεις
                  </div>
                  <div class="d-flex justify-start text-left">
                    Είσαι έμπορος, είσαι καταναλωτής, είσαι οδηγός. Απαντάμε σε
                    κάθε σου ερώτηση.
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="d-flex justify-end">
              <v-col cols="12" md="auto">
                <v-form ref="feedBackForm" style="text-align: left">
                  <h3 class="d-flex justify-start">Η γνώμη σου μετράει!</h3>
                  <div class="d-flex justify-start">
                    Πες μας (ανώνυμα) πώς μπορούμε να βελτιωθούμε!
                  </div>
                  <v-textarea
                    class="pt-5"
                    label="Γράψε εδώ"
                    :rules="rules.emptyRule"
                    auto-grow
                    outlined
                    rows="3"
                    row-height="40"
                    v-model="feedBackContent"
                    hide-details
                  ></v-textarea>
                  <v-checkbox
                    v-model="checkBox"
                    class="pb-4"
                    :rules="rules.checked"
                    label="Αποδέχομαι τους όρους και τις προϋποθέσεις"
                    color="orange"
                    value="orange"
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    class="py-5"
                    color="primary"
                    style="width: 100%; text-transform: initial"
                    @click="subminFeedback"
                  >
                    Αποστολή
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- legal & credits section -->
    <v-row style="background-color: #f6f6f6" class="px-4 py-2">
      <v-col cols="12" md="6" class="d-flex justify-start align-self-center">
        <div>
          <h3 class="d-flex justify-start">Νομικά</h3>
          <div style="width: 200px; border-bottom: 1px solid black"></div>
          <div class="py-3">
            <span v-for="(legal, i) in legalLinks" :key="legal.title">
              <span
                style="cursor: pointer"
                @click="$router.push(legal.link)"
                class="mr-1 legalBtns"
                >{{ legal.title }}</span
              >
              <span class="mr-1" v-if="i < legalLinks.length - 1"> | </span>
            </span>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center justify-md-end align-self-end"
      >
        <p style="color: grey; font-size: 0.9rem">
          Made with love in Greece © STOFERNO AE 2021
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "BottomBar",
  data() {
    return {
      feedBackContent: null,
      checkBox: false,
      rules: {
        checked: [(v) => !!v || "Name is required"],
        emptyRule: [(val) => (val || "").length > 0 || "Υποχρεωτικό πεδίο"],
      },
      socialLogos: [
        { icon: faFacebookF, link: "https://www.facebook.com/stoferno/" },
        { icon: faInstagram, link: "https://www.instagram.com/stoferno.gr" },
        { icon: faTwitter, link: "https://twitter.com/stoferno" },
        {
          icon: faLinkedinIn,
          link: "https://www.linkedin.com/company/stoferno",
        },
        {
          icon: faYoutube,
          link: "https://www.youtube.com/channel/UCPrhHGRwFN_5lWSVPDsUt5w",
        },
      ],
      legalLinks: [
        { title: "Όροι Χρήσης Ιστοσελίδας", link: "legal/terms-of-use-el" },
        { title: "Πολιτική Πληρωμών", link: "legal/privacy-policy-el" },
        { title: "Πολιτική Απορρήτου", link: "legal/payments-policy-el" },
        { title: "Cookies", link: "legal/cookies-policy-el" },
      ],
    };
  },
  methods: {
    ...mapActions({
      feedback: "feedback/feedback",
    }),

    openCareer() {
      let path = this.$router.resolve({
        path: `career`,
      });
      window.open(path.href, "_blank");
    },

    subminFeedback() {
      const feedBackFormValidator = this.$refs.feedBackForm.validate();
      if (feedBackFormValidator) {
        this.feedback({ feedContent: this.feedBackContent, vm: this })
          .then((res) => {
            this.$refs.feedBackForm.reset();
            this.feedBackContent = null;
            this.checkBox = false;
            this.$toast.success("Ευχαριστούμε πολύ!");
          })
          .catch((err) => {
            this.$toast.error("Κάτι πήγε στραβά!");
          });
      }
    },
  },
};
</script>

<style lang="scss">
.legalBtns:hover {
  text-decoration: underline;
}
.v-input--checkbox .v-label {
  font-size: 14px;
}
.clickableButton {
  color: black !important;
  font-weight: bold;
  text-decoration: none;
}
.clickableButton:hover {
  text-decoration: underline;
}
</style>
