<template>
  <div style="background-color: white">
    <!-- 1st Landing  -->
    <Header></Header>
    <div style="height: 100vh">
      <v-row class="business_landing">
        <v-col
          cols
          sm="6"
          md="5"
          class="align-self-center px-md-15 px-4 pt-0"
          style="text-align: left"
        >
          <v-img
            src="@/assets/StofernoCourier-OrangeBackground.png"
            contain
          ></v-img>
          <div class="mt-5 font-weight-bold white--text display-1">
            E-Commerce Courier
          </div>
          <div class="white--text subtitle-3">
            Same-day & Next-day Delivery για E-Shops
          </div>

          <div class="m-t-40 button_shadow">
            <v-text-field
              v-model="id"
              label="Αρ. Αποστολής (πχ Α1234)"
              solo
              hide-details
              height="60px"
            >
              <template v-if="!phoneVisible" v-slot:append>
                <v-btn
                  @click="handleSearch"
                  color="secondary"
                  style="text-transform: initial"
                >
                  Αναζήτηση
                </v-btn>
              </template></v-text-field
            >
            <div v-if="phoneVisible">
              <div class="white--text m-t-20 m-b-20 subtitle-3">
                Για την ορθή επαλήθευση των στοιχείων σας, σημειώστε το τηλέφωνο
                που καταχωρήσατε όταν κάνατε την παραγγελία.
              </div>
              <v-text-field
                v-model="phone"
                type="number"
                class="phoneInput"
                :hide-spin-buttons="true"
                hide-details
                height="60px"
                label="Τηλ. Επικοινωνίας"
                solo
              >
                <template v-slot:append>
                  <v-btn
                    @click="handleSearch"
                    color="secondary"
                    style="text-transform: initial"
                  >
                    Αναζήτηση
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </div>
        </v-col>
        <v-col cols sm="6" md="7">
          <v-img
            src="@/assets/Smaller-Truck-Ad.png"
            width="100vw"
            contain
          ></v-img>
        </v-col>
      </v-row>
    </div>
    <!-- Utilities tracking UI -->
    <div class="container">
      <v-row>
        <v-col offset-lg="2">
          <v-timeline align-top dense class="mt-10">
            <v-timeline-item
              color="black"
              v-for="item in trackingLayoutObject"
              :key="item.title"
            >
              <v-row>
                <v-col cols="6" class="pl-5">
                  <v-row style="flex-direction: column">
                    <v-col lg="9" offset-lg="3" style="text-align: left">
                      <div class="font-weight-bold titleSize">
                        {{ item.title }}
                      </div>
                      <div class="subTitleSize">{{ item.subTitle }}</div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" style="text-align: -webkit-left">
                  <v-img :src="item.image" :width="item.width" contain></v-img>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </div>

    <!-- RIDER -->
    <div class="container mt-10">
      <v-row>
        <v-col
          cols="12"
          sm="5"
          offset-lg="2"
          :style="{ padding: isLargeDevice ? '0 30px' : null }"
        >
          <v-img
            class="riderImg"
            :src="require('@/assets/blog.jpg')"
            contain
          ></v-img>
        </v-col>
        <v-col cols="12" sm="5" class="riderText">
          <div
            style="display: flex; flex-direction: column; padding-left: 14px"
          >
            <div>
              <span class="font-weight-bold" style="font-size: 24px">
                Courier & E-Commerce,
              </span>
              <br />
              <span style="font-size: 18px">
                Tελευταία νέα, τάσεις & βέλτιστες πρακτικές <br />
                που πρέπει να γνωρίζεις
              </span>
            </div>
            <div class="button_shadow">
              <v-btn
                @click="openBlog"
                dark
                height="50px"
                color="#F9B54A"
                class="font-weight-bold m-t-15"
                max-width="200px"
              >
                ΔΙΑΒΑΣΕ ΠΕΡΙΣΣΟΤΕΡΑ
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <div
      class="container"
      :class="{ 'm-t-60': !isLargeDevice, 'm-t-150': isLargeDevice }"
    >
      <p style="font-size: 26px; font-weight: bold">Μας Εμπιστεύτηκαν</p>
      <v-tabs
        :show-arrows="isLargeDevice"
        :class="{ sliderSmall: !isLargeDevice }"
        class="slider"
        optional
      >
        <v-tab
          v-for="brand in partneships"
          :key="brand"
          style="pointer-events: none"
        >
          <v-img :src="brand" contain width="110px"> </v-img>
        </v-tab>
      </v-tabs>
    </div>
    <div
      class="container"
      :class="{
        'm-t-20': !isLargeDevice,
        'm-t-150': isLargeDevice,
        'm-b-150': isLargeDevice,
      }"
    >
      <p style="font-size: 26px; font-weight: bold">Μας Ανέφεραν</p>
      <v-tabs
        :show-arrows="isLargeDevice"
        :class="{ sliderSmall: !isLargeDevice }"
        class="slider"
        optional
      >
        <v-tab
          v-for="brand in NewsChannels"
          :key="brand"
          style="pointer-events: none"
        >
          <v-img :src="brand" contain width="110px"> </v-img>
        </v-tab>
      </v-tabs>
    </div>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import {
  faMapMarkerAlt,
  faChevronDown,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";
import Header from "../../components/Shell-Components/Header.vue";
import BottomBar from "../../components/Shell-Components/BottomBar.vue";
export default {
  components: { Header, BottomBar },
  metaInfo: function () {
    return {
      title: "Stoferno.gr | Business",
      meta: [
        {
          name: "description",
          content: "Stoferno.gr | Business courier, search voucher with id",
        },
      ],
    };
  },

  data() {
    return {
      faMapMarkerAlt,
      faChevronDown,
      faSearch,
      id: null,
      phone: null,
      phoneVisible: false,
      rules: {
        idRule: [(val) => (val || "").length > 0 || "Υποχρεωτικό πεδίο"],
        phoneRule: [(val) => (val || "").length > 9 || "Υποχρεωτικό πεδίο"],
      },
      trackingLayoutObject: [
        {
          title: "Real-time Tracking",
          subTitle: "Tου διανομέα στο χάρτη",
          width: "14rem",
          mobileWidth: "9rem",
          image: require("@/assets/bussiness-landing/Stoferno.gr-Live-Tracking-on-Computer-Asset.png"),
        },
        {
          title: "Εκτιμώμενη ώρα παράδοσης",
          subTitle: "σε 2-ωρα χρονικά περιθώρια",
          width: "11rem",
          mobileWidth: "6rem",
          image: require("@/assets/bussiness-landing/ETA-Icon.png"),
        },
        {
          title: "Είσπραξη Αντικαταβολών",
          subTitle: "με μετρητά ή με POS",
          width: "14rem",
          mobileWidth: "8rem",
          image: require("@/assets/bussiness-landing/Stoferno-Credit-Card.png"),
        },
        {
          title: "Συνεχόμενη ενημέρωση",
          subTitle: "Με Email, SMS & Custom Tracking Link",
          width: "12rem",
          mobileWidth: "9rem",
          image: require("@/assets/bussiness-landing/Live-Updates.png"),
        },
        {
          title: "Μείωση Τηλεφωνικού Κέντρου",
          subTitle: "Κατά 65% λόγω real-time ενημερώσεων",
          width: "12rem",
          mobileWidth: "8rem",
          image: require("@/assets/bussiness-landing/Telephone.png"),
        },
        {
          title: "Εύκολη Διασύνδεση με API",
          subTitle: "Ή απλή καταχώρηση με excel import",
          width: "14rem",
          mobileWidth: "9rem",
          image: require("@/assets/bussiness-landing/Simple-Upload-2.png"),
        },
        {
          title: "Εξελιγμένο Λογισμικό",
          subTitle: "Για τη βέλτιση παρακολούθηση δεμάτων",
          width: "12rem",
          mobileWidth: "8rem",
          image: require("@/assets/bussiness-landing/QR-Scanning.png"),
        },
        {
          title: "97% Eπιτυχία παράδοσης",
          subTitle: "Στην 1η προσπάθεια",
          width: "12rem",
          mobileWidth: "8rem",
          image: require("@/assets/bussiness-landing/Delivery-success-rate.png"),
        },
        {
          title: "Εγγυημένη Ποιότητα Εξυπηρέτησης",
          subTitle: "Διάβασε τις 2000+ αξιολογήσεις",
          width: "15rem",
          mobileWidth: "11rem",
          image: require("@/assets/bussiness-landing/GoogleReviews.png"),
        },
        {
          title: "Real-time Chat",
          subTitle: "Σε κάθε Tracking link",
          width: "12rem",
          mobileWidth: "9rem",
          image: require("@/assets/bussiness-landing/Talk-to-us.png"),
        },
      ],
      partneships: [
        require("@/assets/Partnerships/bed&bath.png"),
        require("@/assets/Partnerships/zakcret.png"),
        require("@/assets/Partnerships/bestprice.png"),
        require("@/assets/Partnerships/altershops.png"),
        require("@/assets/Partnerships/gamerules.png"),
        require("@/assets/Partnerships/iSystem.png"),
        require("@/assets/Partnerships/kostis.png"),
        require("@/assets/Partnerships/mg.png"),
      ],
      NewsChannels: [
        require("@/assets/NewsChannels/epixeiro.png"),
        require("@/assets/NewsChannels/EU-startups.png"),
        require("@/assets/NewsChannels/Fortune-Greece.png"),
        require("@/assets/NewsChannels/Insider.png"),
        require("@/assets/NewsChannels/Kathimerini.png"),
        require("@/assets/NewsChannels/Proto-Thema.png"),
        require("@/assets/NewsChannels/Startupper.png"),
        require("@/assets/NewsChannels/capital.png"),
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLargeDevice: "device/isLargeDevice",
    }),
    validateSearch() {
      if (!this.id || this.id.replace(/^\s+|\s+$/g, "").length < 5) {
        return {
          error: "Μη έγκυρος αριθμός αποστολής",
          isValid: false,
        };
      } else if (this.id.replace(/^\s+|\s+$/g, "").length <= 7) {
        if (!this.phoneVisible) {
          return {
            enablePhone: true,
          };
        }
        if (this.phone && this.phone.length >= 10 && this.phone.length <= 13) {
          return {
            isValid: true,
            id: this.id.replace(/^\s+|\s+$/g, ""),
            phone: this.phone,
            isSmallId: true,
          };
        } else {
          return {
            error: "Μη έγκυρος αριθμός τηλεφώνου",
            isValid: false,
          };
        }
      } else if (this.id.replace(/^\s+|\s+$/g, "").length === 24) {
        return {
          isValid: true,
          id: this.id.replace(/^\s+|\s+$/g, ""),
          isSmallId: false,
        };
      }
      return {
        error: "Μη έγκυρος αριθμός αποστολής",
        isValid: false,
      };
    },
  },
  methods: {
    ...mapActions({
      searchVoucher: "searchvoucher/searchvoucher/searchVoucher",
    }),
    openLiveTracking(id) {
      let path = this.$router.resolve({
        path: `courier-tracking/order/${id}`,
      });
      window.open(path.href, "_blank");
    },
    handleSearch() {
      const { isValid, id, isSmallId, error, phone, enablePhone } =
        this.validateSearch;
      if (enablePhone) return (this.phoneVisible = true);
      if (isValid) {
        if (isSmallId) {
          this.searchVoucher({
            smallId: id,
            phoneNumber: phone,
          })
            .then((id) => {
              this.openLiveTracking(id);
            })
            .catch((err) => {
              this.$toast.error(err);
            });
        } else {
          this.openLiveTracking(id);
        }
      } else {
        this.$toast.error(error);
      }
    },
    openBlog() {
      window.open("https://blog.stoferno.gr/", "_blank");
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
label.v-label.theme--dark {
  color: white;
}
.phoneInput input[type="number"] {
  -moz-appearance: textfield;
}
.phoneInput input::-webkit-outer-spin-button,
.phoneInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.v-btn--is-elevated {
  box-shadow: 0 0 9px 6px rgb(0 0 0 / 10%) !important;
}
.business_landing {
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9b54a;
  padding: 10px 10px;
  margin: 0;

  .button_shadow {
    .v-btn--is-elevated {
      box-shadow: 0 0 9px 6px rgb(0 0 0 / 10%) !important;
    }
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
      > .v-input__control
      > .v-input__slot {
      box-shadow: 0 0 9px 6px rgb(0 0 0 / 10%) !important;
    }
  }
}
.riderImg {
  box-shadow: rgb(172 172 172) 0px 0px 6px 0px;
  border-radius: 14px;
}
.riderText {
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0;
}
.slider {
  .theme--light.v-tabs > .v-tabs-bar {
    background-color: #f4f4f4 !important;
  }
  .v-item-group {
    height: auto;
  }
  .v-slide-group__content {
    justify-content: space-between;
  }
}
.sliderSmall {
  .v-slide-group__prev {
    display: none !important;
  }
}
.blogImg {
  .v-responsive__content {
    width: fit-content !important;
  }
}

@media (max-width: 768px) {
  .titleSize {
    font-size: 1rem !important;
  }
  .subTitleSize {
    font-size: 0.9rem !important;
  }
  .riderText {
    font-size: 1rem !important;
  }
}
@media (min-width: 768px) {
  .titleSize {
    font-size: 1.5rem !important;
  }
  .subTitleSize {
    font-size: 1.1rem !important;
  }
}
.borderRight {
  border-right: 3px solid black;
}
</style>
