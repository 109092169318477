<template>
  <div v-if="isLargeDevice" class="header">
    <div class="text-left d-flex align-center">
      <!-- <v-btn
          dark
          color="#F9B54A"
          class="m-r-10 font-weight-bold buttonStyle"
          style="
            text-transform: initial;
            border: 1px solid white;
            background-color: rgba(255, 255, 255, 0.14);
          "
        >
          Αρχική / Marketplace
        </v-btn> -->
      <v-img
        src="@/assets/StofernoCourier-OrangeBackground.png"
        width="250px"
        class="m-r-20 m-l-10"
        contain
      ></v-img>
      <v-btn
        @click="handleNavigation('/', false)"
        color="#F9B54A"
        dark
        class="m-r-10 font-weight-bold buttonStyle"
        style="
          text-transform: initial;
          border: 1px solid white;
          box-shadow: none !important;
        "
        :style="{
          backgroundColor: isActive('/')
            ? 'white'
            : ' rgba(255, 255, 255, 0.25)',
          color: isActive('/') ? '#F9B54A' : 'white',
        }"
      >
        BUSINESS COURIER
      </v-btn>
      <v-btn
        @click="handleNavigation('https://blog.stoferno.gr/', true)"
        dark
        color="#F9B54A"
        class="m-r-10 font-weight-bold buttonStyle"
        style="
          text-transform: initial;
          border: 1px solid white;
          box-shadow: none !important;
          background-color: rgba(255, 255, 255, 0.25);
        "
      >
        BLOG
      </v-btn>
    </div>
    <div class="text-right">
      <v-btn
        @click="handleNavigation('https://shops.stoferno.gr/', true)"
        dark
        color="#F9B54A"
        class="m-r-10 font-weight-bold buttonStyle"
        style="
          text-transform: initial;
          border: 1px solid white;
          box-shadow: none !important;
          background-color: rgba(255, 255, 255, 0.25);
        "
      >
        ΕΙΣΟΔΟΣ ΣΤΟ MERCHANT PANEL
      </v-btn>
    </div>
  </div>
  <div v-else class="header">
    <v-btn @click="drawer = true" color="white" text>
      <font-awesome-icon
        :icon="faBars"
        color="white"
        class="font-size-30"
      ></font-awesome-icon>
    </v-btn>
    <v-img
      src="@/assets/stoferno-logo-xmas.png"
      max-width="180px"
      contain
    ></v-img>

    <!-- NAVIGATION DRAWER -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      height="100vh"
      width="100vw"
    >
      <div class="closeIcon">
        <v-img
          src="@/assets/stoferno-logo-xmas-orange.png"
          max-width="180px"
          contain
        ></v-img>
        <v-btn @click="drawer = false" color="white" text>
          <font-awesome-icon
            :icon="faTimes"
            color="black"
            class="font-size-30"
          ></font-awesome-icon>
        </v-btn>
      </div>

      <v-list class="mt-4 nav_links_mobile container">
        <v-list-item v-for="item in links" :key="item.title" link>
          <v-list-item-content>
            <v-list-item-title
              class="nav_drawer_links"
              @click="handleNavigation(item.path, item.isExternal)"
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
export default {
  name: "Header",
  data() {
    return {
      faBars,
      faTimes,
      drawer: false,
      links: [
        {
          title: "Είσοδος στο Merchant Panel",
          path: "https://shops.stoferno.gr/",
          isExternal: true,
        },
        {
          title: "Business Courier",
          path: "/",
          isExternal: false,
        },
        {
          title: "Blog",
          path: "https://blog.stoferno.gr/",
          isExternal: true,
        },
        {
          title: "Career",
          path: "/career",
          isExternal: false,
        },
      ],
    };
  },
  methods: {
    handleNavigation(path, isExternal) {
      if (isExternal) {
        window.open(path, "_blank");
      } else {
        const isInSamePath = this.$router.currentRoute.path === path;
        if (!isInSamePath) {
          this.$router.push(path);
        }
      }
      this.drawer = false;
    },
    isActive(path) {
      return this.$router.currentRoute.path === path;
    },
  },
  computed: {
    ...mapGetters({
      isLargeDevice: "device/isLargeDevice",
    }),
  },
  components: {},
};
</script>

<style lang="scss">
.header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f9b54a;
  height: 90px;
  padding: 0 5px;
  align-items: center;
  padding: 0 10px 0 20px;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 0px 4px 0px #1b1b1b;
  .v-overlay {
    height: 100vh;
  }
  .closeIcon {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0 20px;
  }
  .nav_drawer_links {
    font-size: 18px;
    font-weight: 500;
  }
  .nav_links_mobile {
    text-align: left;
  }
}
.navigation_btns {
  position: absolute;
  left: 0%;
  top: 0;
  transform: translate(10%, 100%);
  color: white;
  font-weight: bold;
  width: 85%;
}
.buttonStyle {
  height: 2.6rem !important;
}
</style>
